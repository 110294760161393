import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Image as AntdImage } from 'antd';

const Image = function ({ src, preview, ...rest }) {
  const imageRef = useRef(null);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);

  useEffect(() => {
    setHeight((rest.height / rest.width) * get(imageRef, 'current.clientWidth'));
    setWidth(get(imageRef, 'current.clientWidth'));
  }, [imageRef, rest]);

  return (
    <div
      ref={imageRef}
      className='custom-image'
      style={{
        minWidth: '100%',
      }}
    >
      <AntdImage
        width={width}
        height={height}
        src={src}
        preview={preview}
        loading='eager'
      />
    </div>
  );
};

Image.defaultProps = {
  preview: false,
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

export default Image;
